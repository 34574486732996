import React, { useRef, useState } from 'react';
import { Slider, Button } from '@mui/material';

const GrayscaleImageProcessor = () => {
  const [image, setImage] = useState(null);
  const [binarizedImageUrl, setBinarizedImageUrl] = useState(null);
  const [threshold, setThreshold] = useState(128); // Default threshold value
  const fileInputRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleFactor = Math.min(960 / img.width, 960 / img.height, 1); // Calculate scale factor
        canvas.width = img.width * scaleFactor; // Resize width
        canvas.height = img.height * scaleFactor; // Resize height
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw resized image
        
        // Convert to grayscale
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = avg;     // Red
          data[i + 1] = avg; // Green
          data[i + 2] = avg; // Blue
        }
        ctx.putImageData(imageData, 0, 0);
        applyThreshold(canvas, threshold); // Apply initial threshold
      };
      setImage(file);
    }
  };

  const applyThreshold = (canvas, thresholdValue) => {
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;

    for (let i = 0; i < data.length; i += 4) {
      const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
      const binaryValue = avg >= thresholdValue ? 255 : 0; // Binarize based on threshold
      data[i] = binaryValue;     // Red
      data[i + 1] = binaryValue; // Green
      data[i + 2] = binaryValue; // Blue
    }
    ctx.putImageData(imageData, 0, 0);
    setBinarizedImageUrl(canvas.toDataURL());
  };

  const handleThresholdChange = (event, newValue) => {
    setThreshold(newValue);
    if (image) {
      const img = new Image();
      img.src = URL.createObjectURL(image);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleFactor = Math.min(960 / img.width, 960 / img.height, 1); // Calculate scale factor
        canvas.width = img.width * scaleFactor; // Resize width
        canvas.height = img.height * scaleFactor; // Resize height
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height); // Draw resized image
        applyThreshold(canvas, newValue); // Apply threshold when slider changes
      };
    }
  };

  const downloadBinarizedImage = () => {
    const link = document.createElement('a');
    link.href = binarizedImageUrl;
    link.download = 'binarized_image.png'; // Set the default file name
    link.click();
  };

  return (
    <div className="image-preprocessing">
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
      />
      {binarizedImageUrl && (
        <div>
          <h3 className="text-xl font-bold mb-2">Binarized Image:</h3> {/* Match style with Processed Image */}
          <img src={binarizedImageUrl} alt="Binarized" style={{ maxWidth: '960px', maxHeight: '960px' }} /> {/* Limit size */}
          <div>
            <label>Threshold: {threshold}</label>
            <Slider
              value={threshold}
              onChange={handleThresholdChange}
              min={0}
              max={255}
              valueLabelDisplay="auto"
              sx={{
                color: 'white', // Change slider color to white
                '& .MuiSlider-thumb': {
                  backgroundColor: 'white',
                },
                '& .MuiSlider-track': {
                  backgroundColor: 'white',
                },
                '& .MuiSlider-rail': {
                  backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
              }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button 
              variant="contained" 
              onClick={downloadBinarizedImage} 
              style={{ backgroundColor: 'white', color: 'black' }} // Make button white
            >
              Download
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GrayscaleImageProcessor;