import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './components/HomePage';

// Updated About component
const About = () => (
  <div className="text-left">
    <h2 className="text-3xl font-bold mb-4">What is Colony Counter</h2> {/* Consistent title size */}
    
    <p className="text-lg mb-4">
     Colony-counter.bio was designed to reduce the workload of microbiologists by automating the often time-consuming and repetitive task of colony counting. 
     This process is fundamental to many microbiological experiments, as it enables researchers to estimate the number of Colony Forming Units (CFUs) present in a given sample. 
     Colony detection is accomplished using image processing techniques such as the Hough Transform and Watershed Transform, ensuring accurate and efficient results. 
     Additionally, the software features a manual counting mode, allowing users to manually select and count any colonies that the automatic system may have missed, thereby ensuring the validity of results.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">How to Use</h3> {/* Updated header size to match */}
    <p className="text-lg mb-4">
      To get started, upload your image and use the sliders to adjust the settings, especially the contrast and the Canny threshold, as they are key to obtaining usable results. 
      Ensure that the photos you upload have no light glare or obstructions for the best results. Using a dark background beneath the plate will help clearly distinguish the colonies from each other.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Limitations</h3> {/* Updated header size to match */}
    <p className="text-lg mb-4">
      The transform works best for circular colonies. Please note that this project is still in the early stages of development, and errors or unexpected results are still expected.
    </p>
  </div>
);

const PrivacyPolicy = () => (
  <div className="text-left">
    <h2 className="text-3xl font-bold mb-4">GDPR Compliance Statement</h2>
    <p className="text-lg mb-4"><strong>Effective Date: 21/9/24</strong></p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Introduction</h3>
    <p className="text-lg mb-4">
      At colony-counter.bio, we are committed to protecting and respecting your privacy in accordance with the General Data Protection Regulation (GDPR). This privacy policy explains how we collect, use, and protect personal data obtained through our website.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Data Controller</h3>
    <p className="text-lg mb-4">
      The data controller for this website is Luke O'Keeffe, proprietor and data manager, with an address at 63 Rue Charles III, Nancy, France, 54000. All personal data collected via the Google Analytics tool on our website is managed solely by Luke O'Keeffe and is not shared with any third parties.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Data Collection</h3>
    <p className="text-lg mb-4">
      We collect data through the use of a Google Analytics tag embedded in our website. This data may include, but is not limited to:
      <ul>
        <li>Browser type</li>
        <li>IP address</li>
        <li>Time spent on the website</li>
        <li>Pages visited</li>
        <li>Geographic location (based on IP address)</li>
      </ul>
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Purpose of Data Collection</h3>
    <p className="text-lg mb-4">
      The information collected is used solely for the purpose of improving our website's functionality and user experience. It helps us better understand user behavior and optimize our services accordingly.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Legal Basis for Processing</h3>
    <p className="text-lg mb-4">
      Our legal basis for processing this personal data is legitimate interest, as it is necessary for the smooth operation of our website and for providing users with an optimal browsing experience. All data is collected in accordance with Google Analytics' own GDPR compliance policies.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Data Access</h3>
    <p className="text-lg mb-4">
      Access to the data collected is strictly limited to Luke O'Keeffe. No third parties have access to the data, and it is not shared or sold to any external entities. Google Analytics data is stored securely and in accordance with GDPR requirements.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Data Retention</h3>
    <p className="text-lg mb-4">
      We retain your personal data for as long as necessary to fulfill the purposes described in this policy. After this period, your data will be securely deleted.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Your Rights</h3>
    <p className="text-lg mb-4">
      Under GDPR, you have the following rights regarding your personal data:
      <ul>
        <li>Right to Access: You may request a copy of the personal data we hold about you.</li>
        <li>Right to Rectification: You may ask us to correct any inaccuracies in your data.</li>
        <li>Right to Erasure: You may request that we delete your personal data.</li>
        <li>Right to Restrict Processing: You may request that we limit the processing of your data.</li>
        <li>Right to Object: You may object to the processing of your personal data at any time.</li>
        <li>Right to Data Portability: You may request a copy of your data in a structured, commonly used format.</li>
      </ul>
      To exercise any of these rights, please contact Luke O'Keeffe at [your contact information].
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Cookies</h3>
    <p className="text-lg mb-4">
      Google Analytics may place cookies on your browser to track your activity. By using our website, you consent to the use of these cookies.
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Data Transfers</h3>
    <p className="text-lg mb-4">
      Any data transferred by Google Analytics is done so in accordance with their privacy and security standards, ensuring compliance with GDPR. No data is transferred outside of the European Economic Area (EEA).
    </p>

    <h3 className="text-3xl font-semibold mt-6 mb-4">Contact Information</h3>
    <p className="text-lg mb-4">
      If you have any questions about this GDPR Compliance Statement or wish to exercise your rights, please contact Luke O'Keeffe:
      <br />
      Mailing Address: 63 Rue Charles III, Nancy, France, 54000
    </p>
  </div>
);

const Contact = () => <div>Contact Page</div>;

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Router>
      <div className="min-h-screen flex flex-col items-center font-helvetica bg-black text-white">
        <header className="w-full bg-white p-4 flex items-center justify-between">
          <Link to="/" className="text-black text-3xl font-bold">
            <Link to="/" className="text-black text-3xl font-bold">
              Colony Counter
            </Link>
          </Link>
          <button 
            onClick={toggleMenu} 
            className="text-black focus:outline-none z-50 transition-transform duration-300 ease-in-out"
            style={{ transform: menuOpen ? 'rotate(90deg)' : 'rotate(0deg)' }}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </header>
        
        <div 
          className={`fixed top-0 right-0 h-full bg-white text-black p-4 z-40 w-64 transform transition-transform duration-300 ease-in-out ${
            menuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="mt-16">
            <Link to="/about" className="block py-2" onClick={() => setMenuOpen(false)}>About</Link>
            <Link to="/privacy" className="block py-2" onClick={() => setMenuOpen(false)}>Privacy Policy</Link>
            <Link to="/contact" className="block py-2" onClick={() => setMenuOpen(false)}>Contact</Link>
          </div>
        </div>

        <main className="flex-grow w-full p-4 flex flex-col items-center">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;